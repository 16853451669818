import { Icon, TIconId } from '@nrk/yr-icons';
import classNames from 'classnames';
import './Button.scss';
import { forwardRef } from 'react';
import { Button__Content } from '../Button/Button__Content';

interface IBaseProps {
  buttonType?: 'default' | 'ghost' | 'bleed';
  buttonVariant?: 'accent' | 'neutral' | 'critical';
  buttonSize?: 'small' | 'medium' | 'large';
  buttonShape?: 'radius' | 'rounded';
  ariaLabel?: string;
  iconBefore?: TIconId;
  iconAfter?: TIconId;

  // Disallow data attributes we use internally
  // to prevent anyone from trying to use these by accident
  'data-as'?: never;
  'data-type'?: never;
  'data-variant'?: never;
  'data-size'?: never;
  'data-disabled'?: never;
  'data-shape'?: never;
}

export interface IButtonProps extends IBaseProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  as: 'button';
  htmlFor?: string;
}

export interface ILinkProps extends IBaseProps, React.LinkHTMLAttributes<HTMLAnchorElement> {
  as: 'link';
  isExternal?: boolean;
  href: string;
  htmlFor?: string;
}

interface ILabelProps extends IBaseProps, React.LabelHTMLAttributes<HTMLLabelElement> {
  as: 'label';
  htmlFor: string;
}

interface ISelectProps extends IBaseProps, React.SelectHTMLAttributes<HTMLSelectElement> {
  // TODO(as): This is only used in PageFooter_LanguageSelector. We use standard HtmlSelectElement in Menu__ThemeSelector and Menu__LanguageSelector.
  // We have an old <Select> component wich is no longer in use, this could probably inherit from a <BaseButton> component, so se can remove select from <Button>
  // Incase it is possible to get inspiration from <MultiSelect>, I mention this also - this component is used in WeatherWarningsFilter.
  as: 'select';
  label: string;
  iconAfter?: never; // The select button already has a chevron icon after the label
}

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement | HTMLLabelElement | HTMLSelectElement,
  IButtonProps | ILinkProps | ILabelProps | ISelectProps
>((props, ref) => {
  if (props.as === 'button') {
    const {
      as,
      buttonType = 'ghost',
      buttonVariant = 'accent',
      buttonSize = 'medium',
      buttonShape = 'radius',
      className,
      ariaLabel,
      iconBefore,
      iconAfter,
      children,
      ...htmlProps
    } = props;

    return (
      <button
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        {...htmlProps}
        className={classNames('button', className)}
        data-type={buttonType}
        data-variant={buttonVariant}
        data-size={buttonSize}
        data-shape={buttonShape}
        aria-label={ariaLabel}
      >
        <Button__Content iconBefore={iconBefore} iconAfter={iconAfter}>
          {children}
        </Button__Content>
      </button>
    );
  }

  if (props.as === 'link') {
    const {
      as,
      buttonType = 'ghost',
      buttonVariant = 'accent',
      buttonSize = 'medium',
      buttonShape = 'radius',
      className,
      ariaLabel,
      iconBefore,
      iconAfter,
      isExternal,
      children,
      ...htmlProps
    } = props;
    const { href, ...otherHtmlProps } = htmlProps;

    // This is written as an object we can spread on the link element to make eslint happy
    // so it doesn't think we're adding `target="_blank"` without a corresponding `rel="noopener noreferrer"` attribute.
    const externalLinkProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : undefined;

    return (
      <a
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        {...otherHtmlProps}
        href={href}
        className={classNames('button', className)}
        data-type={buttonType}
        data-variant={buttonVariant}
        data-size={buttonSize}
        data-shape={buttonShape}
        {...externalLinkProps}
      >
        <Button__Content ariaLabel={ariaLabel} iconBefore={iconBefore} iconAfter={iconAfter}>
          {children}
        </Button__Content>
      </a>
    );
  }

  if (props.as === 'label') {
    const {
      as,
      buttonType = 'ghost',
      buttonVariant = 'accent',
      buttonSize = 'medium',
      buttonShape = 'radius',
      className,
      ariaLabel,
      iconBefore,
      iconAfter,
      children,
      ...htmlProps
    } = props;
    const { htmlFor, ...otherHtmlProps } = htmlProps;

    return (
      <label
        ref={ref as React.ForwardedRef<HTMLLabelElement>}
        {...otherHtmlProps}
        htmlFor={htmlFor}
        className={classNames('button', className)}
        data-type={buttonType}
        data-variant={buttonVariant}
        data-size={buttonSize}
        data-shape={buttonShape}
      >
        <Button__Content ariaLabel={ariaLabel} iconBefore={iconBefore} iconAfter={iconAfter}>
          {children}
        </Button__Content>
      </label>
    );
  }

  if (props.as === 'select') {
    const {
      as,
      buttonType = 'ghost',
      buttonVariant = 'accent',
      buttonSize = 'medium',
      buttonShape = 'radius',
      className,
      label,
      ariaLabel,
      iconBefore,
      children,
      ...htmlProps
    } = props;

    return (
      <label
        className={classNames('button', className)}
        data-as={as}
        data-type={buttonType}
        data-variant={buttonVariant}
        data-size={buttonSize}
        data-shape={buttonShape}
        data-disabled={htmlProps.disabled}
      >
        <Button__Content ariaLabel={ariaLabel} iconBefore={iconBefore}>
          {label}
        </Button__Content>

        <Icon id="icon-chevron-down" size={3} />

        <select ref={ref as React.ForwardedRef<HTMLSelectElement>} {...htmlProps} className="button__select">
          {children}
        </select>
      </label>
    );
  }

  return null;
});
