import { COLOR_BACKGROUND_LOWER_DARK, COLOR_BACKGROUND_LOWER_LIGHT } from '@nrk/yr-design-tokens';
import jsCookie from 'js-cookie';
import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import settings from '../../app/settings';
import { reducer } from './helpers/reducer';

export type TTheme = 'dark' | 'light';
export type TPreferredTheme = 'dark' | 'light' | 'system';
interface IProps {
  themeOnLastVisit: TTheme;
  preferredTheme: TPreferredTheme;
  children: React.ReactNode;
}

interface IContext {
  // `theme` is set based on your `preferredTheme` and `themeOnLastVisit`. These are read from the cookies `yr-preferred-theme` and `yr-theme-on-last-visit`.
  theme: TTheme;
  preferredTheme: TPreferredTheme;
  setPreferredTheme: ({ preferredTheme }: { preferredTheme: TPreferredTheme }) => void;
  setTheme: ({ theme }: { theme: TTheme }) => void;
}

const ThemeContext = createContext<IContext | undefined>(undefined);

export function ThemeProvider(props: IProps) {
  const { themeOnLastVisit, preferredTheme, children } = props;

  const [state, dispatch] = useReducer(reducer, { theme: themeOnLastVisit, preferredTheme });

  const setTheme = useCallback(({ theme }: { theme: 'light' | 'dark' }) => {
    // We want to update the meta tag for theme-color when switching between themes.
    const themeColor = document.querySelector('meta[name="theme-color"]');
    const color = theme === 'light' ? COLOR_BACKGROUND_LOWER_LIGHT : COLOR_BACKGROUND_LOWER_DARK;
    if (themeColor) {
      themeColor.setAttribute('content', color);
    }

    document.documentElement.setAttribute('data-theme', theme);

    jsCookie.set(settings.cookies.themeOnLastVisit, theme, {
      expires: 365,
      path: '/',
      sameSite: 'lax'
    });

    dispatch({ type: 'SET_THEME', theme });
  }, []);

  const setPreferredTheme = useCallback(
    ({ preferredTheme }: { preferredTheme: TPreferredTheme }) => {
      jsCookie.set(settings.cookies.preferredTheme, preferredTheme, {
        expires: 365,
        path: '/',
        sameSite: 'lax'
      });

      let theme: TTheme = 'light';

      if (preferredTheme === 'system') {
        theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      } else {
        theme = preferredTheme;
      }

      setTheme({ theme });

      dispatch({ type: 'SET_PREFERRED_THEME', preferredTheme });
    },
    [setTheme]
  );

  const value = useMemo(() => {
    const { theme, preferredTheme } = state;

    return {
      theme,
      preferredTheme,
      setTheme,
      setPreferredTheme
    };
  }, [setPreferredTheme, setTheme, state]);

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

export function useTheme() {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
}
