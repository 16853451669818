import { Icon } from '@nrk/yr-icons';
import { TPreferredTheme, useTheme } from '../../contexts/ThemeContext/ThemeContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Text } from '../Text/Text';

export function Menu__ThemeSelector() {
  const { preferredTheme, setPreferredTheme } = useTheme();
  const translate = useTranslate();

  function changeTheme(event: React.ChangeEvent<HTMLSelectElement>) {
    const preferredTheme = event.target.value as TPreferredTheme;
    setPreferredTheme({ preferredTheme });
  }

  return (
    <div className="menu__theme-selector">
      <Icon className="menu__theme-selector-icon" id="icon-chevron-down" size={2} />
      <select
        className="menu__theme-selector-options"
        id="menu__theme-selector-options"
        onChange={evt => {
          changeTheme(evt);
        }}
        defaultValue={preferredTheme}
      >
        <option value="dark">{translate('themeSelector/option/dark')}</option>
        <option value="light">{translate('themeSelector/option/light')}</option>
        <option value="system">{translate('themeSelector/option/system')}</option>
      </select>
      <label htmlFor="menu__theme-selector-options">
        <Text size="4" weight="bold" lang="nb-no">
          {translate('themeSelector/label')}
        </Text>
      </label>
    </div>
  );
}
