import produce from 'immer';
import { TPreferredTheme, TTheme } from '../ThemeContext';
interface IState {
  theme: TTheme;
  preferredTheme: TPreferredTheme;
}

type TAction = { type: 'SET_THEME'; theme: TTheme } | { type: 'SET_PREFERRED_THEME'; preferredTheme: TPreferredTheme };

export function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case 'SET_THEME':
      return produce(state, draft => {
        draft.theme = action.theme;
      });

    case 'SET_PREFERRED_THEME':
      return produce(state, draft => {
        draft.preferredTheme = action.preferredTheme;
      });

    default:
      throw new Error();
  }
}
