// We need to import custom-elements polyfill before our render
// code is loaded and executed. Ideally we would only ship this to
// legacy browsers, but since Edge 16-18 supports modules but not
// custom-elements, we ship it to everyone.
import { loadableReady } from '@loadable/component';
import '@webcomponents/custom-elements';
import { initializeErrorReporter } from '../lib/errorReporter';
import { initializeLocale } from '../lib/helpers/translations';
import '../styles/main.scss';
import { render } from './browser/render';
import { initializeListeners } from './client/listeners';

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  // Prevent the error from appearing in the console in production
  if (process.env.NODE_ENV === 'production') {
    event.preventDefault();
  }
});

initializeErrorReporter();

const localeCode = window.__LOCALE_CODE__;
const localeTranslations = window.__LOCALE_TRANSLATIONS__;
const dehydratedReactQueryState = window.__REACT_QUERY_STATE__;

initializeLocale(localeCode, localeTranslations);

if (window.__CUTS_THE_MUSTARD__ === true) {
  start();
}

async function start() {
  // Polyfill AbortController if the browser does not support it.
  // Chrome 63-65 supports modules but not AbortController
  // so it doesn't load the polyfills we only give to legacy browsers.
  if ('AbortController' in window === false) {
    await import(
      /* webpackChunkName: "abortcontroller-polyfill" */
      // @ts-ignore
      'abortcontroller-polyfill/dist/polyfill-patch-fetch'
    );
  }

  // This function gets called automatically by @loadable when all required scripts have finished loading.
  loadableReady(() => {
    const supportsPerformance =
      'performance' in window && 'mark' in window.performance && 'measure' in window.performance;

    const startMark = 'hydration start';
    const endMark = 'hydration end';

    if (supportsPerformance) {
      window.performance.mark(startMark);
    }

    render({
      // TODO(scb): Reimplement SSR=false
      hydrate: true,
      dehydratedReactQueryState,
      initialTotalFavouritedLocations: window.__TF__,
      initialTotalVisitedLocations: window.__TV__,
      themeOnLastVisit: window.__THEME_ON_LAST_VISIT__,
      preferredTheme: window.__PREFERRED_THEME__
    });

    initializeListeners();

    if (supportsPerformance) {
      window.performance.mark(endMark);

      // Measure how long the hydration itself took
      window.performance.measure('hydrating', startMark, endMark);
    }
  });
}
