import { Icon } from '@nrk/yr-icons';
import { useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createPageUrl } from '../../app/redirects';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';
import { Menu } from '../Menu/Menu';

interface IProps {
  currentPageId: string;
  searchButtonId: string;
  searchInputId: string;
}

export const PageHeader__Toolbar = (props: IProps) => {
  const { currentPageId, searchButtonId, searchInputId } = props;
  const { currentPageSettings } = useAppState();
  const { expandedSearchInput } = currentPageSettings;

  const localeCode = useLocaleCode();
  const translate = useTranslate();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  function closeMenu() {
    setShowMenu(false);
  }

  function openMenu() {
    setShowMenu(true);
  }

  return (
    <nav className="page-header__toolbar">
      <label
        htmlFor={searchInputId}
        className="page-header__toolbar-icon-button"
        // We use aria-hidden since focusing this element will focus the search element wich has the correct aria-attributes
        aria-hidden={true}
      >
        <Icon id="icon-search" size={3} />
      </label>
      {expandedSearchInput === false && (
        // We are hiding the search button for pages where the search input is expanded - i.e. the search page
        <Button
          id={searchButtonId}
          as="label"
          htmlFor={searchInputId}
          className="page-header__toolbar-button"
          iconBefore="icon-search"
          // We use aria-hidden since focusing this element will focus the search element wich has the correct aria-attributes
          aria-hidden={true}
          data-testid="page-header__search-input-label"
          data-mode="js"
        >
          {translate('header/search')}
        </Button>
      )}
      <Button
        as="link"
        iconBefore="icon-search"
        className="page-header__toolbar-button"
        href={createPageUrl({ localeCode, pageId: 'search' })}
        data-mode="no-js"
        data-page-id={currentPageId}
      >
        {translate('header/search')}
      </Button>
      <button
        type="button"
        className="page-header__toolbar-icon-button"
        aria-label={translate('header/menu')}
        onClick={() => {
          openMenu();
        }}
      >
        <Icon id="icon-menu" size={3} />
      </button>
      <Button
        as="button"
        id="page-header__menu-button"
        className="page-header__toolbar-button"
        iconBefore="icon-menu"
        onClick={() => {
          openMenu();
        }}
        data-mode="js"
      >
        <span>{translate('header/menu')}</span>
      </Button>

      {showMenu === true && (
        <Menu
          onToggle={() => {
            closeMenu();
          }}
        />
      )}
    </nav>
  );
};
